import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #1b8ef7;
        font-size: 56px;
        line-height: 1.18;
        padding: 0rem;
        margin: 0rem;

        @media only screen and (max-width: 926px) {
            font-size: 35px;
          }

        @media only screen and (max-width: 896px) {
          font-size: 35px;
        }

        @media only screen and (max-width: 768px) {
            padding: 25px;
            padding: 0;
          }
      
        @media only screen and (max-width: 414px) {
            font-size: 25px;
            padding: 0;
        }
    }

    p {
        color: #18216d;
        font-size: 21px;        
        line-height: 1.41;

        @media only screen and (max-width: 768px) {
            font-size: 15px;
            
          }
      
        @media only screen and (max-width: 414px) {
            font-size: 15px;
            
        }

    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #1b8ef7;

        :hover {
            color: #1b8ef7;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .character {
       
        font-size: 20px;
        border-radius: 8px;
       
        /* light theme */
        color: #272729;
        background-color: #fff;
        box-shadow: 0 2px 0 #e4e2f5;
        border: 1px solid #ddd;
       
        /* dark theme */
        // color: #fff;
        // background-color: #222;
        // box-shadow: 0 2px 0 #444;
    }

    .character--selected {
        position: relative;
    }

    /* optionally hide placeholder (in case it overlaps the cursor) */
    .character--selected:not(.character--filled) {
      color: transparent;
    }
     
    .character--selected:not(.character--filled)::after {
      content: "";
      width: 1px;
      height: 25px;
      background-color: #333;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: blink 1s infinite step-end;
    }

    .container {
        border: none;
    }

    /* Style the modal */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.modal-text {
  margin-left: 14px;
  font-size: 24px;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #ddd;
  border-top: 8px solid #2196f3;
  border-bottom: 8px solid #2196f3;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w3-pink, .w3-hover-pink:hover {
    color: #fff!important;
    background-color: #e91e63!important;
}
.w3-panel {
    margin-top: 16px;
    margin-bottom: 16px;
}
.w3-container, .w3-panel {
    padding: 0.8em;
}

.w3-panel h3 {
    color: #fff;
    font-size: 16px
}

.w3-panel p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0rem;
    margin-top: 0rem;
}
.erro-verify{
  text-align:center;
  max-width: 550px;
  margin: auto;
  font-weight: 900;
  color: rgb(27, 142, 247);

}
    
`;
